<template lang="pug">
include ../pug/svg
div#insertMedia.modal.center(:class="{active:showMediaModal||showEmbedModal}")
	div.bar
		+svg(svg-filename="iconMore" aria-hidden="true")
		span {{label}}
		button.but(type="button" @click="closeModal") Close
			+svg(svg-filename="iconClose")
			
	MediaEmbed.body(v-if="showEmbedModal" :mediaType="mediaType" :multiple="multiple" v-model:selected="selectedEmbed" @insertEmbed="insertEmbed" @closeEmbedModal="closeModal")
	
	div.body(v-else-if="showMediaModal")
		//-MediaGrid(:modal="true" :mediaType="mediaType" :multiple="multiple" v-model:selected="selectedMedia")
		//-div.foot
			button.but.pri(@click="insertMedia" :disabled="!selectedMedia.length") Insert
			button.but.sec(@click="closeModal") Cancel
		MediaGrid(:modal="true" :mediaType="mediaType" :multiple="multiple" v-model:selected="selectedMedia" @insertMedia="insertMedia" @closeModal="closeModal")
</template>

<script>
import MediaGrid from '../components/MediaGrid';
import MediaEmbed from '../components/MediaEmbed';
import { mapFields } from 'vuex-map-fields';

export default {
	name: 'MediaModal',
	components: {
		MediaGrid,
		MediaEmbed,
	},
	props: ['mediaType','multiple'], // validation handeld in MediaGrid component
	emits: ['mediaInsert','embedInsert'],
	data() {		
		return {
			selectedMedia: [],
			selectedEmbed: [],
		};
	},
	computed: {
		...mapFields('gui', [ // get/set vuex store
			'showMediaModal',
			'showEmbedModal',
			'tintState',
		]),
		label() {
			const media = this.terminology('media','media','singular');
			return (this.showEmbedModal) ? 'Embed ' + media : 'Insert ' + media;
		},
	},
	methods: {
		terminology(groupKey, termKey, varKey) {
			return this.$store.getters['gui/customTerm'](groupKey, termKey, varKey);
		},
		closeModal() {
			this.selectedMedia = [];			
			this.selectedEmbed = [];			
			// update gui
			this.showMediaModal = false;
			this.showEmbedModal = false;
			this.tintState = false;			
		},
		insertMedia() {
			const media = (this.multiple) ? this.selectedMedia : this.selectedMedia[0];
			this.$emit('mediaInsert', media);
			this.closeModal();
		},
		insertEmbed() {
			const embed = (this.multiple) ? this.selectedEmbed : this.selectedEmbed[0];
			this.$emit('embedInsert', embed);
			this.closeModal();			
		},
	},
}
</script>

<style lang="scss">
</style>
