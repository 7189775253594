<template lang="pug">
include ../pug/svg
div#settings(v-if="course")
	Form.wrapper(ref="settingsForm" :validation-schema="settingsSchema" v-slot="{ values, errors, meta }")
		div.c1
			div.field.media.hero(:class="{selected:course.media||course.mediaEmbedUrl}")
				figure
					picture
						img(v-if="course.media" :src="course.media.mediaUrl" :alt="course.media.seoAlt")
						img(v-else-if="course.mediaEmbedUrl" :src="course.mediaEmbedUrl")
					div.butdbl.text(:class="{unselected : !course.media && !course.mediaEmbedUrl}")
						button.but(type="button" @click="selectMedia()") Media
						button.but(type="button" @click="removeMedia()")
							span Remove Media
							+svg(svg-filename="iconBin")
						button.but(type="button" @click="selectEmbed()") Embed
					figcaption.label Hero Image
			div.field
				Field(name="publishStatus" as="select" placeholder="Unsupplied" autocomplete="off" v-model="publishStatus" :required="true" :validateOnInput="false" v-slot="{value}" @change="enableCheck")
					option(v-for="(status, index) in filteredCourseStatuses" :value="status.value" :selected="status&&status.value===publishStatus") {{status.option}}
				label Status
				+svg(svg-filename="iconArrow" aria-hidden="true" alt="Arrow icon")
		div.c2
			FieldText.grow(name="name" label="Name" v-model="name" :required="true" :cancelled="cancelled" :errors="errors" @keyup="enableCheck" @blur="enableCheck")
			FieldText.grow(name="slug" label="Slug" v-model="slug" :disabled="true" :required="false" :cancelled="cancelled" :errors="errors" @keyup="enableCheck" @blur="enableCheck")
			
			div.field
				input(type="checkbox" v-model="courseAllowRetake" value="true" @change="enableCheck" :true-value="1" :false-value="0")
				label {{terminology('course','course','singular')}} / Allow Retake
				span.on(v-if="courseAllowRetake") Yes
				span.off(v-else) No
				
			div.field
				input(type="checkbox" v-model="lessonChronological" value="true" @change="changeChronological" :true-value="1" :false-value="0")
				label {{terminology('course','lesson','singular')}} / Access
				span.on(v-if="lessonChronological") Chronological
				span.off(v-else) Nonchronological
				
			div.field
				input(type="checkbox" v-model="lessonAllowReturn" @change="enableCheck" :true-value="1" :false-value="0" :disabled="!this.lessonChronological")
				label {{terminology('course','lesson','singular')}} / Return to previous
				span.on(v-if="lessonAllowReturn") Allow
				span.off(v-else) Prevent
			div.field
				input(type="checkbox" v-model="examMode" value="true" @change="enableCheck" :true-value="1" :false-value="0")
				label {{terminology('course','course','singular')}} / Exam mode
				span.on(v-if="examMode") On
				span.off(v-else) Off
			div.field
				input(type="checkbox" v-model="examShowAllocation" value="true" @change="enableCheck" :true-value="1" :false-value="0")
				label Task / Point allocation
				span.on(v-if="examMode") Show
				span.off(v-else) Hide
				
			FieldText(name="examPassScore" :label="terminology('course','course','singular')+' / Exam Pass Score'" v-model="examPassScore" :required="examMode?true:false" :disabled="examMode?false:true" :cancelled="cancelled" append="Points" :numeric="true" :maxlength="4" :errors="errors" @keyup="enableCheck" @blur="enableCheck")
			
	MediaModal(mediaType="image" :multiple="false" @mediaInsert="mediaInsert" @embedInsert="embedInsert")
</template>

<script>
import { Form, Field } from 'vee-validate';
import * as Yup from 'yup';
import FieldText from '../components/FieldText';
import MediaModal from '../components/MediaModal';
import { mapFields } from 'vuex-map-fields';
import { createHelpers } from 'vuex-map-fields';

const { mapFields: mapCourseFields } = createHelpers({
	getterType: 'courses/getCourseField',
	mutationType: 'courses/updateCourseField',
});

export default {
	name: 'ManageLearningCourseSettings',
	components: {
		Form,
		Field,
		FieldText,
		MediaModal,
	},
	props: ['course', 'cancelled'],
	emits: ['storeUpdated'],
	data () {
		const settingsSchema = Yup.object().shape({
		});
		
		return {
			settingsSchema,
		};
	},
	computed: {
		...mapCourseFields([ // uses custom getter: getCourseField
			'name',
			'slug',
			'media',
			'mediaEmbedUrl',
			'publishStatus',
			'courseAllowRetake',
			'lessonAllowReturn',
			'lessonChronological',
			//'maxPlaces',
			//'publishStatus',
			//'seoDescription',
			'examMode',
			'examPassScore',
			'examShowAllocation',
		]),
		...mapFields('courses', [ // get/set vuex store
			'courseStatuses',
		]),
		...mapFields('gui', [ // get/set vuex store
			'showMediaModal',
			'showEmbedModal',
			'tintState',
		]),
		filteredCourseStatuses() {
			const lessonCount = this.$store.getters['courses/getCourseLessonCount'](this.course.id);
			return (lessonCount) ? this.courseStatuses : this.courseStatuses.filter(s => s.value !== 'live');
		},
	},
	watch: {
		course: {
			handler(course) {
				this.$store.dispatch('gui/setHeader', {
					title: course.name,
					backRoute: {
						text: this.terminology('course','course','plural'),
						name: 'ManageLearning',
					},
				});		
			},
			deep: false,
		},
	},
	methods: {
		changeChronological() {
			console.log('changeChronological:', this.lessonChronological, this.lessonAllowReturn);
			if (!this.lessonChronological) {
				// nonchronological
				this.lessonAllowReturn = 1;
			}
			this.enableCheck();
		},
		enableCheck() {
			console.log('enableCheck');			
			const meta = this.$refs.settingsForm.getMeta();
			this.$emit('storeUpdated', { valid: meta.valid });
		},
		selectMedia() {
			this.tintState = true;
			this.showMediaModal = true;
		},
		selectEmbed() {
			this.tintState = true;
			this.showEmbedModal = true;
		},
		mediaInsert(media) {
			this.media = media;
			this.mediaEmbedUrl = null;
			this.enableCheck();
		},
		embedInsert(embed) {
			this.media = null;
			this.mediaEmbedUrl = embed.mediaUrl;				
			this.enableCheck();			
		},
		removeMedia() {
			this.media = null;
			this.mediaEmbedUrl = null;
			this.enableCheck();
		},
		terminology(groupKey, termKey, varKey) {
			return this.$store.getters['gui/customTerm'](groupKey, termKey, varKey);
		},
	},
}
</script>

<style lang="scss">
	div#settings {
		width: 100%;
		form.wrapper {
			display: flex;
			margin-top: 30px;
			.c2 {
				flex-grow: 1;
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				.field {
					width: calc(50% - 5px);
				}
			}
		}
		@media only screen and (max-width:599px) {
			form.wrapper {
				.c2 {
					.field {
						max-width: none;
						width: 100%;
					}
				}
			}
		}
		@media only screen and (max-width:899px) {
			form.wrapper {
				flex-direction: column;
				.field {
					&.media {
						max-width: 600px;
						margin-left: auto;
						margin-right: auto;
					}
				}
			}
		}
		@media only screen and (min-width:900px) {
			form.wrapper {
				.c1 {
					width: 410px;
					min-width: 410px;
					margin-right: 20px;
				}
			}
		}
	}
	.field {
		&.media {
			padding: 0;
			overflow: hidden;
			.label {
				position: absolute;
				top: 10px;
				left: 10px;
			}
			&.selected {
				.label {
					color: #FFF;
				}
			}
			picture {
				aspect-ratio: 16/9;
				display: block;
				margin: 0;
				background: url(/img/icon-photo.svg) center no-repeat;
				background-size: 30px 30px;
				&:after {
//					z-index: 1;
					content: '';
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					background: rgba(#FFF, 0.5);
					transition: opacity 0.3s ease-in-out;
					opacity: 0;
				}
			}
			.butdbl {
				z-index: 2;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				pointer-events: none;				
				opacity: 0;
			}
			&:hover {
				.butdbl,
				picture:after {
					pointer-events: auto;
					opacity: 1;
				}
			}
		}
	}
</style>
